<template>
    <div>
        <form method="POST" class="bg-white sm:mx-auto sm:max-w-xl lg:mx-0" @submit.prevent="handleSubmit"
            action="/api/waitlist">
            <div class="min-w-0 flex-1 mb-3"><label class="sr-only block text-gray-700 text-sm font-bold mb-2">Email</label>
                <input v-model="email" id="email" name="email" type="email" placeholder="Enter Email"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6" />
            </div><label class="sr-only block text-gray-700 text-sm font-bold mb-2">Zipcode</label>
            <div class="sm:flex">
                <div class="min-w-0 flex-1"><input id="zip" name="zipcode" placeholder="Enter Zipcode Ex: 90210"
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                        v-model="zipcode"></div>
                <div class="mt-3 sm:mt-0 sm:ml-3"><button type="submit"
                        class="block w-full rounded-md bg-sky-500 py-2 px-4 text-white shadow hover:bg-sky-400 focus:outline-none focus:ring-2 focus:ring-sky-300">Join
                        Waitlist</button></div>
            </div>
        </form>
        <ClientOnly>
        <Dialog :open="isOpen" @close="setIsOpen(false)">
            <div class="fixed inset-0 bg-white/30 backdrop-blur-sm" aria-hidden="true" />

            <div class="fixed inset-0 flex items-center justify-center p-4">

                <DialogPanel class="w-full max-w-lg rounded-md bg-white border shadow p-4 text-gray-600">
                    <DialogTitle class="text-center font-semibold text-md">Thank you for your interest</DialogTitle>
                    <DialogDescription>
                    </DialogDescription>

                    <p class="py-4 leading-7">
                        We have added you to our waitlist and are grateful 🙏 for your support. You will receive an email when HomeKiq is available in your area.
                    </p>

                    <button class="px-2 py-1.5 border rounded shadow focus:outline-none focus:ring-1 focus:ring-sky-300" @click="setIsOpen(false)">Close</button>
                </DialogPanel>
            </div>
        </Dialog>
        </ClientOnly>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import {
    Dialog,
    DialogPanel,
    DialogTitle,
    DialogDescription,
} from '@headlessui/vue'

const email = ref('')
const zipcode = ref('')
const isOpen = ref(false)

const handleSubmit = async () => {
    const res = await $fetch("/api/waitlist", {
        method: 'POST',
        body: {
            email: email.value,
            zipcode: zipcode.value
        }
    })

    email.value = ''
    zipcode.value = ''
    isOpen.value = true
}

function setIsOpen(val: boolean) {
    isOpen.value = val
}
</script>